// src/Media.js
import { createMedia } from "@artsy/fresnel"

const QueryBreakpoints = createMedia({
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1360,
    xxxl: 1600,
  },
})

// Generate CSS to be injected into the head
export const mediaStyles = QueryBreakpoints.createMediaStyle()
export const { Media, MediaContextProvider } = QueryBreakpoints
