// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-box-scores-js": () => import("./../../../src/pages/box-scores.js" /* webpackChunkName: "component---src-pages-box-scores-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-league-rules-settings-schedule-js": () => import("./../../../src/pages/league-rules-settings-schedule.js" /* webpackChunkName: "component---src-pages-league-rules-settings-schedule-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-season-1-results-js": () => import("./../../../src/pages/season-1-results.js" /* webpackChunkName: "component---src-pages-season-1-results-js" */),
  "component---src-pages-teams-berry-js": () => import("./../../../src/pages/teams/berry.js" /* webpackChunkName: "component---src-pages-teams-berry-js" */),
  "component---src-pages-teams-downey-js": () => import("./../../../src/pages/teams/downey.js" /* webpackChunkName: "component---src-pages-teams-downey-js" */),
  "component---src-pages-teams-evans-js": () => import("./../../../src/pages/teams/evans.js" /* webpackChunkName: "component---src-pages-teams-evans-js" */),
  "component---src-pages-teams-gillan-js": () => import("./../../../src/pages/teams/gillan.js" /* webpackChunkName: "component---src-pages-teams-gillan-js" */),
  "component---src-pages-teams-hemsworth-js": () => import("./../../../src/pages/teams/hemsworth.js" /* webpackChunkName: "component---src-pages-teams-hemsworth-js" */),
  "component---src-pages-teams-holland-js": () => import("./../../../src/pages/teams/holland.js" /* webpackChunkName: "component---src-pages-teams-holland-js" */),
  "component---src-pages-teams-klementieff-js": () => import("./../../../src/pages/teams/klementieff.js" /* webpackChunkName: "component---src-pages-teams-klementieff-js" */),
  "component---src-pages-teams-lui-js": () => import("./../../../src/pages/teams/lui.js" /* webpackChunkName: "component---src-pages-teams-lui-js" */),
  "component---src-pages-teams-mackie-js": () => import("./../../../src/pages/teams/mackie.js" /* webpackChunkName: "component---src-pages-teams-mackie-js" */),
  "component---src-pages-teams-olsen-js": () => import("./../../../src/pages/teams/olsen.js" /* webpackChunkName: "component---src-pages-teams-olsen-js" */),
  "component---src-pages-teams-pratt-js": () => import("./../../../src/pages/teams/pratt.js" /* webpackChunkName: "component---src-pages-teams-pratt-js" */),
  "component---src-pages-teams-reynolds-js": () => import("./../../../src/pages/teams/reynolds.js" /* webpackChunkName: "component---src-pages-teams-reynolds-js" */),
  "component---src-pages-teams-rudd-js": () => import("./../../../src/pages/teams/rudd.js" /* webpackChunkName: "component---src-pages-teams-rudd-js" */),
  "component---src-pages-teams-russo-js": () => import("./../../../src/pages/teams/russo.js" /* webpackChunkName: "component---src-pages-teams-russo-js" */),
  "component---src-pages-transactions-js": () => import("./../../../src/pages/transactions.js" /* webpackChunkName: "component---src-pages-transactions-js" */)
}

